import React from "react"
import styled from "@emotion/styled"
import Brook from "../../images/brand/brook-canesten.png"
import Layout from "../../components/global/layout"
import Button from "../../components/form/Button"
import Cookies from "js-cookie"
import { navigate } from "gatsby"
import { urls } from "../../data/urls"

const Grid = styled.div`
  display: grid;
  padding: 0 24px;
  grid-template-rows: 1fr max-content;
  gap: 32px;
  height: 100%;
`
const Content = styled.div`
  overflow: auto;
  mask-image: linear-gradient(
    to top,
    transparent 0px,
    black 40px,
    black calc(100% - 16px),
    transparent 100%
  );
  padding: 16px 0;
`
const Small = styled.p`
  font-size: 0.7rem;
  text-align: center;
  color: ${props => props.theme.shade[30]};
`
const BrandLogo = styled.img`
  width: 160px;
  margin: 16px auto;
`
const Actions = styled.div`
  display: grid;
  gap: 16px;
`

export default function index(props) {
  function accept() {
    Cookies.set("gatsby-gdpr-google-tagmanager", true, { expires: 30 })
    navigate(urls.INTRO)
  }

  function decline() {
    Cookies.set("gatsby-gdpr-google-tagmanager", false, { expires: 30 })
    navigate(urls.INTRO)
  }

  return (
    <Layout title="Privacy & cookie policy" hideHamburger={true}>
      <Grid>
        <Content>
          <p>
            This website uses cookies to give you the best experience and help
            us improve site performance. To find out what cookies we use and how
            to disable non-essential ones please read our &nbsp;
            <a
              href="https://www.brook.org.uk/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
          </p>
        </Content>
        <Actions>
          <Button onClick={accept} backgroundColor={props => props.theme.rose}>
            Yes, I agree
          </Button>
          <Button
            onClick={decline}
            backgroundColor={props => props.theme.rose}
            buttonStyle="ghost"
          >
            Decline and continue
          </Button>
          <BrandLogo src={Brook} alt="Brook & Canesten Logos" />
          <Small>Developed by Brook in partnership with Canesten.</Small>
        </Actions>
      </Grid>
    </Layout>
  )
}
