import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const Container = styled.button`
  width: 100%;
  border-radius: ${props => props.theme.radius.xl};
  background-color: ${props => props.theme.shade[100]};
  padding: 12px 12px;
  cursor: pointer;
  font-weight: 600;

  background-color: ${props => {
    if (props.buttonStyle === "ghost") {
      return "transparent"
    } else {
      if (props.buttonStyle === "inverted") {
        return props.theme.shade[100]
      } else {
        return props.backgroundColor
      }
    }
  }};
  color: ${props => {
    if (props.buttonStyle === "ghost") {
      return props.backgroundColor
    } else {
      if (props.buttonStyle === "inverted") {
        return props.backgroundColor
      } else {
        return props.theme.shade[100]
      }
    }
  }};
  border: solid 1px
    ${props =>
      props.buttonStyle === "ghost"
        ? props.backgroundColor
        : props.buttonStyle === "inverted"
        ? "transparent"
        : "transparent"};
`

export default function Button({
  onClick,
  children,
  buttonStyle,
  backgroundColor,
}) {
  return (
    <Container
      buttonStyle={buttonStyle}
      backgroundColor={backgroundColor}
      onClick={onClick}
    >
      {children}
    </Container>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  buttonStyle: PropTypes.string,
  backgroundColor: PropTypes.any,
}
